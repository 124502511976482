export const ROUTES = {
  PACKAGE_DETAILS: "/package",
  PUBLIC_CART: "/cart",
  SUCCESS_CART: "/cart/checkout/success",
  PRIVACY_POLICY: "/privacypolicy",
  CHECKOUT: "/cart/checkout",
  DASHBOARD: "/dashboard",
  NOTICE: "/notices-events",
  LOGIN: "/login",
  PROFILE: "/dashboard/profile",
  COURSES: "/dashboard/courses",
  TESTS: "/dashboard/test-packages/:packageId",
  DISCUSSION_COMMUNITY: "/dashboard/community-discussion",
  QUESTIONS_COMMUNITY: "/dashboard/community-questions",
  LIBRARY: "/dashboard/library/:folderId?/:contentType?",
  ORDER: "/dashboard/order",
  ORDER_DETAILS: "/dashboard/order/details/:id",
  CART: "/dashboard/cart",
  WISHLIST: "/dashboard/wishlist",
  BOOKMARKS: "/dashboard/bookmark/:folderId/:contentType?",
  PERFORMANCE_REPORT: "/dashboard/performance-report",
  REFERRAL: "/dashboard/referral",
  HELP_AND_SUPPORT: "/dashboard/support",
  FEEDBACK: "/dashboard/feedback",
  WALLET: "/dashboard/wallet",
  TRANSACTION_HISTORY: "/dashboard/wallet/transaction-history",
  SETTINGS: "/dashboard/settings",
  LOGOUT: "/dashboard/logout",
  EXAM_WINDOW: "/exam/start/",
  EXAM_MAIN: "/exam/main/:testId/:attemptId/:attemptStatus",
  EXAM_SUMMARY: "/exam/summary/:testId/:attemptId/:attemptStatus",
  EXAM_REPORT: "/exam/report",
  COURSE_DETAILS: "/dashboard/courses/:courseId",
  TEST_PACKAGES: "/dashboard/test-packages",
  TEST_ANALYSIS: "/dashboard/exam/analysis/:packageId/:testId/:attemptId",
  TEST_DISCUSSIONS: "/dashboard/exam/discussion/:packageId/:testId/:type",
  TEST_LEADERS: "/dashboard/exam/leaders/:packageId/:testId/:attemptId?",
  ASSIGNMENT_LEADERS: "/dashboard/assignment/leaders/:packageId/:testId",
  PACKAGES: "/packages",
  // AUDIO_FILES:'/dashboard/courses/:courseId/audios',
  // VIDEO_FILES:'/dashboard/courses/:courseId/videos',
  // PDF_FILES:'/dashboard/courses/:courseId/pdf',
  // TEXT_FILES:'/dashboard/courses/:courseId/files',
  COURSE_CONTENT:
    "/dashboard/courses/:courseId/:contentType/:subjectId?/:contentId?",
  // VIDEO_PORTAL:'/dashboard/courses/:courseId/videos/:subjectId/:videoId',
  VIDEO_PORTAL: "/dashboard/video/:videoId",
  LIBRARY_VIDEO_PORTAL: "/dashboard/library/videos/:videoId",
  LIBRARY_FOLDER_VIDEO_PORTAL:
    "/dashboard/library-folder/:folderId/videos/:videoId",
  AUDIO_PORTAL: "/dashboard/audio/:audioId",
  LIBRARY_AUDIO_PORTAL: "/dashboard/library/audios/:audioId",
  LIBRARY_FOLDER_AUDIO_PORTAL:
    "/dashboard/library-folder/:folderId/audios/:audioId",
  BOOKS: "/books",
  CURRENT_AFFAIRS: "/current-affairs",
  AUDIO_FILES: "/dashboard/courses/:courseId/audios",
  VIDEO_FILES: "/dashboard/courses/:courseId/videos",
  PDF_FILES: "/dashboard/courses/:courseId/documents",
  TEXT_FILES: "/dashboard/courses/:courseId/texts",
  SINGLE_COMMUNITY_FORUM: "/dashboard/community-discussion/:id",
  SINGLE_QUESTION_COMMUNITY: "/dashboard/community-questions/:id",
  LIBRARY_FOLDER: "/dashboard/library-folder/:folderId/:contentType?",
  BOOKMARK_FOLDER: "/dashboard/bookmark-folder/:folderId/:contentType?",
  MAGAZINES: "/dashboard/magazines",
  DEMO_CONTENT: "/demo-content/:packageId/:courseId?/:subjectId?",
  NOTIFICATIONS: "/notifications",
  TERMS_SERVICE: "/terms-conditions",
  ABOUT_US: "/about-us",
  CONTACT_US: "/contact",
  STUDENT_TOKEN: "/dashboard/ticket/:id",
  DOUBTS: "/doubts",
  PACKAGE_PERFORMANCE: "/dashboard/package-performance-report/:packageId",
  PERFORMANCE_REPORT_PACKAGES: "/dashboard/report-tests",
  PACKAGE_DEMO_CONTENT: "/package-demo/:packageId",
  DEMO_VIDEO_PORTAL: "/dashboard/package-demo/video/:contentId",
  DEMO_AUDIO_PORTAL: "/dashboard/package-demo/audio/:contentId",
  SURVEY: "/survey",
  LIVE_CLASSES: "/dashboard/live-class",
  ZOOM_CLASS: "/private/class/live",
  YOUTUBE_LIVE: '/yt'
};

export const CUSTOM_ROUTES = {
  JOBS: "https://blog.competitioncommunity.com/blogs/category/Jobs/1",
  RESULTS: "https://blog.competitioncommunity.com/blogs/category/Results/1",
  DOWNLOADS: "https://blog.competitioncommunity.com/blogs/category/Downloads/1",
  CAREERS: "https://blog.competitioncommunity.com/blogs/category/Career/1",
  BLOGS: "https://blog.competitioncommunity.com/",
};
