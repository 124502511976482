import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Box, Button, HStack, Input } from "@chakra-ui/react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useTracker } from "../../routes/Courses/useTracker";

export const PdfViewer = ({
  url,
  handle,
  initialPage = 1,
  handlePageChange,
  trackingData,
  height = "90vh",
}) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const { trackerTrack } = useTracker(trackingData || {});

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (offset) => {
    setCurrentPage((prevPage) =>
      Math.min(Math.max(prevPage + offset, 1), numPages)
    );
  };

  useEffect(() => {
    return () => trackerTrack(currentPage, numPages, false);
  }, [currentPage, numPages, trackerTrack]);

  return (
    <FullScreen handle={handle}>
      <Box h={height}>
        <HStack
          spacing={4}
          p={2}
          bg="gray.100"
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          <Button onClick={() => changePage(-1)} disabled={currentPage <= 1}>
            Previous
          </Button>
          <Input
            type="number"
            value={currentPage}
            onChange={(e) => setCurrentPage(Number(e.target.value))}
            max={numPages}
            min={1}
            width="4rem"
          />
          <Button
            onClick={() => changePage(1)}
            disabled={currentPage >= numPages}
          >
            Next
          </Button>
          <Box>
            Page {currentPage} / {numPages}
          </Box>
        </HStack>
        <Box h="100%" overflow="hidden">
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading="Loading PDF..."
          >
            <Page pageNumber={currentPage} width={window.innerWidth * 0.8} />
          </Document>
        </Box>
      </Box>
    </FullScreen>
  );
};
